/*
 ** Responsive Breakpoints
 ** https://getbootstrap.com/docs/4.0/layout/grid/#grid-options
 */
const breakpoints = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  sl: "1400px" // Super Large, Additional Breakpoint
};

/*
 ** Main Theme Object
 */
const theme = {
  // Primary Colors
  colors: {
    // Primary Colors
    background: "#F5FAFF",
    hover: "#CCE7FF",
    bluePrimary: "#0D2437",
    blue30: "#12314D",
    blue50: "#1E5280",
    blue75: "#2D7ABF",
    blue80: "#3082CC",
    blue100: "#8FCAFF",
    black: "#11151D",
    grey56: "#8D8D8F",
    grey90: "#E5E5E5",
    grey99: "#FBFCFD",
    link: "#286DAB",
    white: "#FFFFFF",
    textColor: "#11151D",
    textHighContrast: "#F5FAFF",
    actionGreen: "#008000",
    actionRed: "#B2292E",
    actionGreenLight: "#DBFFDB",
    actionRedLight: "#F8E2E3",
    primaryGradient: `linear-gradient(
      150deg,
      #0D2437 15%,
      #1E5280 94%
    )`,
    // OLD COLORS
    primary: "#0D2437",
    primaryShades: {
      0: "#12314D",
      1: "#1E5280",
      2: "#3082CC",
      3: "#8FCAFF",
      4: "#CCE7FF"
    },
    statuses: {
      1: "#FF8E72", // Waiting
      2: "#1E5280", // In Progress
      3: "#662E9B", // Reassigned
      4: "#008F00", // Complete
      5: "#FFCC00", // On Hold
      6: "#D2001C" // Technical Issue
    },
    docStatuses: {
      completed: "#DBFFDB",
      pending: "#FFCC00",
      deleted: "#F8E2E3",
    },
    occurrenceTypes: {
      1: "#662E9B", // Absence
      2: "#8D94BA", // Break Time Exceeded
      3: "#8D94BA", // Arrived Late
      4: "#8D94BA", // Leaving Early
      5: "#8D94BA", // Lunch Time Exceeded
      6: "#FF8E72", // Mid-Shift Logout
      7: "#662E9B", // Missed Shift
      8: "#662E9B" // Absence - NCNS
    },
    redventures: "#B62032",
    successColor: "#1ead1e",
    dangerColor: "#B2292E",
    cautionColor: "#EC9928",
    borderColor: "#E5E5E5",
    darkGrey: "#8D8D8F",
    mediumGrey: "#DADADA",
    lightGrey: "#F2F5F7",
    whiteGrey: "#FBFCFD",
    opaque: "rgba(255,255,255, .95)",
    mute: "#5C5D5D",
    statusShades: {
      default: "#8D94BA",
      "Arrived Late": "#8D94BA",
      Absence: "#662E9B",
      "Mid-Shift Logout": "#FF8E72"
    }
  },
  // Fonts
  baseSize: "16px",
  fonts: {
    bodyFamily: '"Inter", sans-serif',
    bodyWeight: 400,
    headerFamily: '"Inter", sans-serif',
    headerWeight: 600,
  },
  // Breakpoints
  breakpoints: breakpoints,
  // Layout Breakpoints
  sidebarWidth: "240px",
  sidebarCollapsedWidth: "68px",
  navbarHeight: "65px",
  // OLD STYLES
  shadow: "0 2px 4px 0 rgba(0, 0, 0, .1)",
  materialShadows: {
    0: "none",
    1: "0 1px 3px 0 rgba(48, 49, 51, .1)",
    2: "0 2px 4px 0 rgba(48, 49, 51, .1)",
    3: "0 4px 8px 0 rgba(48, 49, 51, .1)",
    4: "0 8px 16px 0 rgba(48, 49, 51, .1)",
    5: "0 16px 24px 0 rgba(48, 49, 51, .1)"
  }
};

export { breakpoints, theme };
