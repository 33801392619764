import React, { Fragment, useMemo } from "react";
import { useAuth0 } from "../../util/auth";
import LoginScreen from "./LoginScreen";

function Authentication({ children }) {
  // Properties off Auth0
  const { isAuthenticated, animation } = useAuth0();

  return useMemo(() => {
    return (
      <Fragment>
        {(!isAuthenticated || animation !== "done") && (
          <LoginScreen animation={animation} />
        )}
        {isAuthenticated && children}                
      </Fragment>
    );
  }, [isAuthenticated, animation, children]);
}

export default Authentication;
