import styled, { createGlobalStyle } from "styled-components";

// Global Style
const GlobalStyle = createGlobalStyle`
  html, body{
    background: ${(props) => props.theme.colors.background};
  }

  body {
    color: ${(props) => props.theme.colors.textColor};
    font-family: ${(props) => props.theme.fonts.bodyFamily};
    font-weight: ${(props) => props.theme.fonts.bodyWeight};
  }
`;

// Styled Header
const StyledHeader = styled.header`
  font-family: ${(props) => props.theme.fonts.headerFamily};
  font-weight: ${(props) => props.theme.fonts.headerWeight};
  color: ${(props) => props.theme.colors.blue50};
  margin-bottom: 16px;

  &.productive-heading-02 {
    font-size: 18px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.bluePrimary};
  }

  &.productive-heading-03 {
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 700;
    color: ${(props) => props.theme.colors.blue50};
  }

  &.productive-heading-04 {
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.blue50};
  }

  &.productive-heading-05 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    color: ${(props) => props.theme.colors.blue30};
  }

  .meta-link {
    font-size: 0.8rem;
    text-transform: none;
  }
`;

// Styled Paragraph
const StyledParagraph = styled.p`
  font-family: ${(props) => props.theme.fonts.bodyFamily};
  font-weight: ${(props) => props.theme.fonts.bodyWeight};

  display: ${(props) => (props.display ? props.display : null)}
  align-items: ${(props) => (props.align ? props.align : null)}
  justify-content: ${(props) => (props.justify ? props.justify : null)}
`;

// Background Sections
const StyledSection = styled.section`
  background: ${(props) =>
    props.noBackground ? "transparent" : props.theme.colors.whiteGrey};
  padding: ${(props) => (props.noPadding ? "0" : "1rem")};
  /* If Includes A Responsive Table */
  .table-responsive {
    background: ${(props) => props.theme.colors.white};
    box-shadow: ${(props) => props.theme.materialShadows[3]};
    border-radius: 5px;
  }
`;
const StyledLabel = styled.span`
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;

  letter-spacing: 0.32px;

  /* Blue / 50 */

  color: ${(props) => props.theme.colors.primaryShades[1]};
`;

// Panel Stuff
const PanelHeader = styled.header.attrs((props) => ({
  weight: props.thin ? "400" : props.theme.fonts.headerWeight,
  letterSpacing: props.spaced ? "1px" : "0px",
}))`
  ${(props) =>
    props.flex && {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .action + .action {
      margin-left: 1rem;
    }
  }

  .selection {
    font-size: 1rem;
  }
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.white};
  margin: 0;
  padding: 2rem;
  z-index: 2;
  &:after {
    content: "";
    position: absolute;
    height: 1px;
    background-color: ${(props) => props.theme.colors.borderColor};
    width: calc(100% - 2rem);
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
`;

const PanelBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem 2rem 2rem;

  font-family: ${(props) => props.theme.fonts.bodyFamily};
  font-style: normal;
`;

const PanelFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  border-top: ${(props) => props.theme.colors.borderColor};
  border-top-width: calc(100% - 2rem);
  width: 490px;
  height: 96px;
  left: 0px;
  bottom: 0;
  padding: 2rem;
  position: sticky;
  /* Greys / White */

  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

// Styled Table
const StyledTable = styled.div`
  padding-bottom: 40px;
  -webkit-font-smoothing: antialiased;
  table {
    layout: fixed;
    width: 100%;
    border-radius: 4px;
  }
  tr {
    height: 48px;
    text-align: left;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey90};
  }
  thead,
  th {
    padding: 16px;
    background-color: ${(props) => props.theme.colors.hover};
  }
  td {
    padding: 16px;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.blue30};
  }
  td:nth-child(1) {
    color: ${(props) => props.theme.colors.blue50};
    font-weight: 600;
  }
    .arrow-icon {
      background-color: ${(props) => props.theme.colors.white};
      width: 100%
      display: inline-block;
      text-align: right;
      border: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.grey90};
  }
  .attendance {
    tr:hover td {
      background-color: ${(props) => props.theme.colors.hover};
    }
  }
}
`;

// Styled Edl Table is specific for Edl data
const StyledEdlTable = styled.div`
  padding-bottom: 40px;
  -webkit-font-smoothing: antialiased;
  table {
    layout: fixed;
    width: 100%;
    border-radius: 4px;
  }
  tr {
    height: 32px;
    text-align: left;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey90};
  }
  thead, th {
    font-size: 12px;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 9px;
    padding-left: 16px;
    background-color: ${(props) => props.theme.colors.hover};
  }
  td {
    padding-top: 8px;
    padding-bottom: 9px;
    padding-left: 16px;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.blue30};
    font-size: 12px;
  }
    .arrow-icon {
      background-color: ${(props) => props.theme.colors.white};
      width: 100%
      display: inline-block;
      text-align: right;
      border: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.grey90};
  }
`;

const StyledLink = styled.span`
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.link};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

const StyledTextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 2rem;

  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey90};
  position: relative;
  font-family: ${(props) => props.theme.fonts.bodyFamily};
  font-weight: ${(props) => props.theme.fonts.bodyWeight};
  font-size: 0.9rem;
  min-width: 12rem;
  flex: 1 0 auto;

  textarea {
    width: 100%;
    padding: 1em;
    border: none;
    &.error {
      outline-color: ${(props) => props.theme.colors.dangerColor};
      outline-width: 2px;
    }
    &:focus {
      outline-color: ${(props) => props.theme.colors.blue75};
      outline-width: 2px;
    }
    &:active {
      border-bottom-color: ${(props) => props.theme.colors.blue75};
    }
  }
`;
const StyledTextInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 2rem;

  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey90};
  position: relative;
  font-family: ${(props) => props.theme.fonts.bodyFamily};
  font-weight: ${(props) => props.theme.fonts.bodyWeight};
  font-size: 0.9rem;
  min-width: 12rem;
  flex: 1 0 auto;

  input {
    width: 100%;
    padding: 1em;
    border: none;
    &.error {
      outline-color: ${(props) => props.theme.colors.dangerColor};
      outline-width: 2px;
    }
    &:focus {
      outline-color: ${(props) => props.theme.colors.blue75};
      outline-width: 2px;
    }
    &:active {
      border-bottom-color: ${(props) => props.theme.colors.blue75};
    }
  }
`;

const StyledDropDown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey90};

  position: relative;
  font-family: ${(props) => props.theme.fonts.bodyFamily};
  font-weight: ${(props) => props.theme.fonts.bodyWeight};
  font-size: 0.9rem;
  min-width: 14rem;
  flex: 1 0 auto;
  background-color: ${(props) => props.theme.colors.white};

  svg {
    width: 18px;
    height: auto;
    color: ${(props) => props.theme.colors.primaryShades[1]};
  }
  .selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 1rem;
    height: 40px;
    width: 100%;
    &:hover {
      background-color: ${(props) => props.theme.colors.primaryShades[4]};
      cursor: pointer;
    }
  }

  .value {
    padding: 8px 16px;
    width: 100%;
    &.inactive {
      color: ${(props) => props.theme.colors.darkGrey};
    }
    &.active {
      color: ${(props) => props.theme.colors.primaryShades[1]};
    }
  }
  ul {
    max-height: 400px;
    overflow-y: scroll;
  }
  ul.options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    //max-height: ${(props) => (props.large ? "20rem" : "10rem")};
    overflow-y: auto;
    z-index: 10;
    background-color: ${(props) => props.theme.colors.white};
    margin: 0;
    padding: 0;
    list-style-type: none;
    box-shadow: ${(props) => props.theme.materialShadows[2]};
    opacity: 0;
    color: ${(props) => props.theme.colors.blue50};
    visibility: hidden;
    transition: all 0.2s ease;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  li.option {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    color: ${(props) => props.theme.colors.blue50};
    border-bottom: 1px solid ${(props) => props.theme.colors.grey90};
    &:hover {
      background-color: ${(props) => props.theme.colors.primaryShades[4]};
      cursor: pointer;
    }
  }
`;

const StyledCheck = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 2rem;
  width: 100%;

  font-family: ${(props) => props.theme.fonts.bodyFamily};
  font-weight: ${(props) => props.theme.fonts.bodyWeight};
  font-size: 0.9rem;
  flex: 1 0 auto;

  form {
    width: 100%;
  }

  .form-check {
    width: 100%;
    height: 40px;
    color: ${(props) => props.theme.colors.blue50};
    border-bottom: 1px solid ${(props) => props.theme.colors.grey90};

    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.primaryShades[4]};
      cursor: pointer;
    }
  }

  .form-check-input {
    color: ${(props) => props.theme.colors.blue50};
  }

`;
const StyledMultiCheck = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 2rem;
  width: 100%;

  font-family: ${(props) => props.theme.fonts.bodyFamily};
  font-weight: ${(props) => props.theme.fonts.bodyWeight};
  font-size: 0.9rem;
  flex: 1 0 auto;

  form {
    width: 100%;
  }

  ul.options {
    width: 100%;
    height: auto;
    background-color: ${(props) => props.theme.colors.white};
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: ${(props) => props.theme.colors.blue50};
    transition: all 0.2s ease;
  }

  li.option {
    width: 100%;
    height: 40px;
    color: ${(props) => props.theme.colors.blue50};
    border-bottom: 1px solid ${(props) => props.theme.colors.grey90};
    &:hover {
      background-color: ${(props) => props.theme.colors.primaryShades[4]};
      cursor: pointer;
    }
  }
  .form-check {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
  }

  .form-check-input {
    color: ${(props) => props.theme.colors.blue50};
  }
`;
const StatusOk = styled.div`
  height: 21px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  background: ${(props) => props.theme.colors.docStatuses.completed};
  color: #008f00;
  font-size: 14px;
`;

const StatusOkSmall = styled.div`
  height: 19px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding: 2px 8px;
  color: #008f00;
  background: ${(props) => props.theme.colors.docStatuses.completed};
  font-size: 12px;
`;

const StatusWarning = styled.div`
  height: 21px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  font-size: 14px;
  color: #665200;
  background: ${(props) => props.theme.colors.docStatuses.pending}3F;
`;

const StatusWarningSmall = styled.div`
  height: 19px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding: 2px 8px;
  font-size: 12px;
  color: #665200;
  background: ${(props) => props.theme.colors.docStatuses.pending}3F;
`;

const StatusDanger = styled.div`
  height: 21px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  font-size: 14px;
  color: #b2292e;
  background: ${(props) => props.theme.colors.docStatuses.deleted}; ;
`;

const StatusDangerSmall = styled.div`
  height: 19px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding: 2px 8px;
  font-size: 12px;
  color: #b2292e;
  background: ${(props) => props.theme.colors.docStatuses.deleted};
`;

export {
  GlobalStyle,
  StyledHeader,
  StyledParagraph,
  StyledSection,
  PanelHeader,
  PanelBody,
  PanelFooter,
  StyledTable,
  StyledEdlTable,
  StyledTextArea,
  StyledTextInput,
  StyledDropDown,
  StyledCheck,
  StyledMultiCheck,
  StyledLabel,
  StyledLink,
  StatusOk,
  StatusOkSmall,
  StatusWarning,
  StatusWarningSmall,
  StatusDanger,
  StatusDangerSmall,
};
