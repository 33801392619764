// src/react-auth0-wrapper.js
import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import jsCookie from "js-cookie";
import jwtDecode from "jwt-decode";

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({ children }) => {

  const [auth, setAuth] = useState({
    client: null,
    loading: true,
    isAuthenticated: jsCookie.get("hrgAccessToken") ? true : false
  });

  // For determining who is logged in, and if they are a dev. Resolved in ApplicationLayout component
  // Role
  // If we're in development preset the role to admin isDeveloper to true
  // This helps with live reloading
  const [role, setRole] = useState(
    process.env.NODE_ENV === "development" ? "admin" : ""
  );
  const [isDeveloper, setIsDeveloper] = useState(
    process.env.NODE_ENV === "development"
  );

  // Are we animating?
  const [animation, setAnimation] = useState(
    jsCookie.get("hrgAccessToken") ? "done" : "not-started"
  );

  useEffect(() => {
    if (animation === "not-started" && auth.isAuthenticated && !auth.loading) {
      // Start Animation
      setAnimation("animating");
      setTimeout(() => {
        setAnimation("done");
      }, 750);
    }
  }, [animation, auth]);

  // Custom Login Function so we can set loading to true when button us clicked
  function handleLogin(...p) {
    setAuth({
      ...auth,
      loading: true
    });
    auth.client.loginWithRedirect(...p);
  }

  function handleLogout(...p) {
    jsCookie.remove("hrgAccessToken");
    auth.client.logout(...p);
  }

  useEffect(() => {
    const initAuth0 = async () => {
      // Start Loading Animation
      setAuth({
        ...auth,
        loading: true
      });
      const auth0 = await createAuth0Client({
        domain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
        client_id: `${process.env.REACT_APP_AUTH_CLIENTID}`,
        audience: `${process.env.REACT_APP_AUTH_AUDIENCE}`,
        redirect_uri: window.location.origin + "/employees",
        connection: "okta"
      });

      if (window.location.search.includes("code=")) {
        await auth0.handleRedirectCallback();        
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      } else if (window.location.search.includes("error=")) {
        // Query Data for Queried business                
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }

      const isAuthenticated = await auth0.isAuthenticated();

      if (isAuthenticated) {
        const token = await auth0.getTokenSilently();
        const parsedToken = jwtDecode(token);        
        // Set Token to Storage with the token's expiration from Auth.
        jsCookie.set("hrgAccessToken", token, { expires: new Date(parsedToken.exp * 1000) });

        // TODO:
        // Based off the parsed token set the user's role
        const scopes = parsedToken.scope.split(" ");
        if (scopes.includes("role:admin")) {
          setRole("admin");
        }

        // Enable development mode if the user is a developer
        if (scopes.includes("role:developer")) {
          setIsDeveloper(true);
        }

        // Set Auth Client
        setAuth({
          loading: false,
          client: auth0,
          isAuthenticated,
          token,
          user: {
            name: {
              first: parsedToken["https://firstName"],
              last: parsedToken["https://lastName"]
            },
            employeeID: parsedToken["https://employeeId"],
            email: parsedToken["https://email"],
            jobFamily: parsedToken["https://jobFamily"],
            scopes: parsedToken.scope.split(" ")
          }
        });
      } else {
        jsCookie.remove("hrgAccessToken");
        setAuth({
          loading: false,
          client: auth0,
          isAuthenticated,
          token: null,
          user: null
        });
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        role: role,
        setRole: setRole,
        animation: animation,
        isAuthenticated: auth.isAuthenticated,
        user: auth.user,
        isDeveloper: isDeveloper,
        token: auth.token,
        // Consider the Application loading if either of these are yet to be resolved
        loading: auth.loading,
        getIdTokenClaims: (...p) => auth.client.getIdTokenClaims(...p),
        getTokenSilently: (...p) => auth.client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth.client.getTokenWithPopup(...p),
        logout: (...p) => handleLogout(...p),
        login: (...p) => handleLogin(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
