import React, { useContext, useState } from "react";

export const SearchContext = React.createContext();
export const useSearchSettings = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
  let [lastSearch, setLastSearch] = useState("");

  // Set last search eid 
  const setSearch = id => {
    setLastSearch(id);
  }

  return (
    <SearchContext.Provider
      value={{
        setSearch,
        lastSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
