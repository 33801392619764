import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/hrg-logo.svg";
import { useAuth0 } from "../../util/auth";
import AlgoliaSearchBar from "../Search/AlgoliaSearchBar";
import avatar from "../../assets/avatar.svg";
import { request, configs } from "../../util/client";

const StyledSiteHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.theme.navbarHeight};
  background: ${(props) => props.theme.colors.white};
  z-index: 20;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left,
  .right {
    line-height: 1;
    display: flex;
    align-items: center;
  }
  .left {
    width: calc(${(props) => props.theme.sidebarWidth} - 2rem);
    .logo {
      display: block;
      text-align: center;
      width: 100%;
    }
    svg {
      height: 35px;
      width: auto;
      cursor: pointer;
      margin: auto;
    }
  }

  /* Search Container */
  .search-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 2rem;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 0.5rem;
      transform: translateY(-50%);
      color: ${(props) => props.theme.colors.grey56};
      width: 15px;
      height: auto;
    }
    input.form-control {
      padding-left: 2rem;
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid ${(props) => props.theme.colors.grey90};
      width: 464px;
      height: 48px;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.16px;
      color: ${(props) => props.theme.colors.grey56};
      font-family: ${(props) => props.theme.fonts.bodyFamily};
      font-weight: ${(props) => props.theme.fonts.bodyWeight};
      &:hover {
        background-color: ${(props) => props.theme.colors.background};
        outline: none;
        box-shadow: none;
      }
      &:focus {
        background-color: ${(props) => props.theme.colors.background};
        color: ${(props) => props.theme.colors.bluePrimary};
        outline: none;
        box-shadow: none;
      }
    }
  }
`;

const StyledAvatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;  
  background-image: url(${(props) => props.avatar});
  background-size: cover;
  background-position: center;
`;

const Avatar = () => {
  const [profilePic, setProfilePic] = useState(avatar);
  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      request({
        route: `${configs.HRGURL}/employees/${user.employeeID}`,
      })
        .then(
          ({
            employeeProfile: {
              employee_profile_image: { medium_profile_image },
            },
          }) => {
            if (
              medium_profile_image !==
              "https://photos.empx.redventures.io/default"
            ) {
              setProfilePic(medium_profile_image);
            }
          }
        )
        .catch((err) => {
          console.warn({ err });
        });
    }
  }, [user]);

  return <StyledAvatar avatar={profilePic} />;
};

const SiteHeader = () => {
  return (
    <StyledSiteHeader>
      <div className="left">
        <Link to="/employees/" className="logo">
          <Logo />
        </Link>
      </div>
      <div className="right">
        <div className="search-container">
          <AlgoliaSearchBar />
        </div>
        <Avatar />
      </div>
    </StyledSiteHeader>
  );
};

export default SiteHeader;
