import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import { ChevronRight, ChevronLeft } from "react-feather";
// icons
import { ReactComponent as Employees } from "../../assets/icons/nav-employees.svg";
import { ReactComponent as EDL } from "../../assets/icons/nav-edl.svg";
import { ReactComponent as Attendance } from "../../assets/icons/nav-attendance.svg";
import { ReactComponent as Doc } from "../../assets/icons/nav-docs.svg";

// will need context from search to keep track of last user searched
import { useSearchSettings } from "../../contexts/search";

const StyledSidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: ${props => props.theme.colors.white};
  padding-top: ${props => props.theme.navbarHeight};
  box-shadow: 2px 1px 6px rgba(30, 82, 128, 0.15);
  z-index: 10;

  nav {
    transition: width 0.25s ease-in-out;
    width: ${props =>
      props.collapsed
        ? props.theme.sidebarCollapsedWidth
        : props.theme.sidebarWidth};
  }

  a,
  .collapse-toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
    color: ${props => props.theme.colors.blue50};
    transition: all 0.2s ease-in;

    .icon {
      position: relative;
      height: 60px;
      min-width: 60px;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .text {
      flex: 1;
      margin-left: 0.5rem;
      font-family: ${props => props.theme.fonts.headerFamily};
      font-weight: 500;
    }
  }

  .link {
    border-left: 8px solid ${props => props.theme.colors.white};
    &:hover {
      background-color: ${props => props.theme.colors.hover};
      text-decoration: none;
    }

    &.active {
      text-decoration: none;
      border-left: 8px solid #8FCAFF;
      background-color: ${props => props.theme.colors.blue50};
      color: ${props => props.theme.colors.white};
      path {
        stroke: white;
      }
    }
  }

  .collapse-toggle {
    border-top: 1px solid ${props => props.theme.colors.grey90};
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    justify-content: flex-end;
    &:hover {
      cursor: pointer;
      background-color: ${props => props.theme.colors.hover};
    }
  }
`;

function Sidebar(props) {
  const { collapsed, toggleCollapse } = props;
  const { lastSearch } = useSearchSettings();

  return (
      <StyledSidebar collapsed={collapsed}>
        <nav>
          <NavLink className="link" key="Employee" to={`/employees/${!lastSearch ? "" : lastSearch}`}>
            <span className="icon"><Employees/></span>
            <span className="text">Employee</span>
          </NavLink>
          <NavLink className="link" key="Attendance" to="/attendance/">
            <span className="icon"><Attendance/></span>
            <span className="text">Attendance</span>
          </NavLink>
          <NavLink className="link" key="EDLs" to="/edl/">
            <span className="icon"><EDL/></span>
            <span className="text">EDL Management</span>
          </NavLink>
          <NavLink className="link" key="Documents" to="/documents/">
            <span className="icon"><Doc/></span>
            <span className="text">Doc Management</span>
          </NavLink>
        </nav>
        <span className="collapse-toggle" onClick={() => toggleCollapse()}>
          <span className="icon">
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </span>
        </span>
      </StyledSidebar>
  );
}

export default withRouter(Sidebar);
