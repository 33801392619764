import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  Highlight,
  connectSearchBox,
} from "react-instantsearch-dom";
import { Link } from "react-router-dom";
import { request } from "../../util/client";
import { Card, Form } from "react-bootstrap";
import { StyledParagraph } from "../../styles/components";
import { Search } from "react-feather";
import avatar from "../../assets/avatar.svg";

const StyledSearchContainer = styled.div`
  input:disabled {
    background: none;
  }

  display: flex;
  align-items: center;
  .ais-SearchBox-form {
    display: flex;
    align-items: center;
    &:focus {
      background-color: ${(props) => props.theme.colors.background};
      outline: none;
      box-shadow: none;
    }
  }
  .ais-SearchBox-reset,
  .ais-SearchBox-submit {
    border: none;
    position: static;
  }
  && {
    svg.ais-SearchBox-resetIcon {
      right: 0.5rem;
      left: auto;
    }
  }
  .ais-Hits {
    position: absolute;
    background-color: ${(props) => props.theme.colors.white};
    top: 100%;
    margin-top: 1.5rem;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    box-shadow: 0px 2px 4px rgba(30, 82, 128, 0.2);
    z-index: 1000;
  }
  .ais-Hits {
    margin-top: 0;
    pointer-events: none;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.15s, transform 0.15s;
  }
  &.active .ais-Hits {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0);
    :focus {
      outline: none;
      box-shadow: none;
    }
  }
  .ais-Hits-list {
    list-style: none;
    padding-left: 0;
    padding-bottom: 0;
  }

  .ais-Hits-item {
    border-bottom: 1px solid ${(props) => props.theme.colors.grey90};
  }
  .ais-Highlight {
    color: ${(props) => props.theme.colors.textColor};
    padding: 0 0.25em;
  }
  .ais-Highlight-highlighted {
    font-style: normal;
    font-weight: ${(props) => props.theme.fonts.headerWeight};
    color: ${(props) => props.theme.colors.blue75};
  }
`;

const SearchResultCard = styled(Card)`
  &:hover {
    text-decoration: none;
  }
  .card-body {
    padding: 10px;
    display: flex;
    align-items: flex-start;
    .picture {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background: ${(props) => props.theme.colors.white};
      background-image: url(${(props) => props.picture});
      background-size: cover;
      background-position: center;
    }
    .info {
      margin-left: 1rem;
    }
    ${StyledParagraph} {
      font-size: 12px;
      color: ${(props) => props.theme.colors.blue30};
      margin-bottom: 0.75rem;
    }
    .name {
      font-size: 18px;
    }
  }
`;

const AlgoliaSearchBar = ({ history }) => {
  const [algoliaToken, setAlgoliaToken] = useState(null);
  const [searchClient, setSearchClient] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState(true);
  const HeaderSearchBar = connectSearchBox(HeaderSearch);

  useEffect(() => {
    (async () => {
      const requestAlgoliaToken = process.env.REACT_APP_ALGOLIA_SEARCH_URL;
      const token = await request({
        method: "POST",
        route: requestAlgoliaToken,
      }).then((res) => {
        if (res) {
          return res.token;
        } else {
          return null;
        }
      });
      setAlgoliaToken(token);
    })();
  }, []);

  useEffect(() => {
    if (algoliaToken) {
      setSearchClient(
        algoliasearch(
          process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
          algoliaToken
        )
      );
      setLoadingSearch(false);
    }
  }, [algoliaToken]);

  const DisabledSearchBar = () => (
    <StyledSearchContainer>
      <Search />
      <Form.Control
        type="search"
        placeholder={`Search Employees`}
        disabled={true}
      />
    </StyledSearchContainer>
  );

  return searchClient && !loadingSearch ? (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.REACT_APP_ALGOLIA_SEARCH_INDICES}
    >
      <HeaderSearchBar history={history} defaultRefinement="" />
    </InstantSearch>
  ) : (
    <DisabledSearchBar />
  );
};

const HeaderSearch = ({ currentRefinement, refine, history }) => {
  useEffect(() => {
    // if the route changes, reset the search query to an empty search
    let unlisten = history.listen(() => {
      refine("");
    });

    return unlisten;
  }, [history, refine]);

  return (
    <StyledSearchContainer className={currentRefinement.length > 0 && "active"}>
      <Search />
      <Form.Control
        type="search"
        placeholder={`Search Employees`}
        name="search"
        value={currentRefinement}
        onChange={(e) => refine(e.currentTarget.value)}
        autoComplete="off"
      />
      <Hits hitComponent={Hit} />
    </StyledSearchContainer>
  );
};

function Hit({ hit }) {

  let employeePhoto = avatar;
  if (hit.thumb_image !== "https://photos.empx.redventures.io/default") {
    employeePhoto = hit.thumb_image;
  }

  return (
    <SearchResultCard
      to={`/employees/${hit.objectID}/`}
      as={Link}
      picture={employeePhoto}
    >
      <Card.Body>
        <div className="picture" />
        <div className="info">
          <StyledParagraph className="name">
            <Highlight attribute="title" hit={hit} />
          </StyledParagraph>
          <StyledParagraph>
            <Highlight attribute="meta_one" hit={hit} /> &#8226;{" "}
            <Highlight attribute="meta_two" hit={hit} /> &#8226;{" "}
            <Highlight attribute="meta_three" hit={hit} />
          </StyledParagraph>
        </div>
      </Card.Body>
    </SearchResultCard>
  );
}

export default withRouter(AlgoliaSearchBar);
