import React, { useState } from "react";
import styled from "styled-components";
import SiteHeader from "./SiteHeader";
import Sidebar from "./Sidebar";

// Global state of last person searched. This is used in Sidebar nav.
import { SearchProvider } from "../../contexts/search";

const StyledLayout = styled.div`
  transition: padding 0.25s ease-in-out;
  padding: ${props =>
    props.collapsed
      ? `${props.theme.navbarHeight} 0 0 ${props.theme.sidebarCollapsedWidth}`
      : `${props.theme.navbarHeight} 0 0 ${props.theme.sidebarWidth}`};
  background-color: ${props => props.theme.colors.background};
`;

function ApplicationLayout({ children }) {
  // Initial State
  let [collapsed, setCollapsed] = useState(false);

  return (
    <SearchProvider>
      <SiteHeader/>
      <Sidebar              
        collapsed={collapsed}
        toggleCollapse={() => setCollapsed(!collapsed)}
      />
      <StyledLayout collapsed={collapsed}>{children}</StyledLayout>
    </SearchProvider>
  );
}

export default ApplicationLayout;
