import jsCookie from "js-cookie";

const configs = {
  HRGURL: process.env.REACT_APP_HRG_URL
};

/**
 * Request Route
 * @param method
 * @param {String} route
 * @param data
 * @param body
 * @param isAuth
 * @param headers
 */
async function request({
  method = "GET",
  route,
  data = null,
  body = null,
  isAuth = true,
  headers = {},
}) {
  let requestHeaders = new Headers();
  let token = jsCookie.get("hrgAccessToken");
  if (isAuth) {
    requestHeaders.append("Authorization", `Bearer ${token}`);
  }

  for (const key in headers) {
    requestHeaders.append(key, headers[key])
  }

  let requestOptions = {
    method,
    mode: "cors",
    headers: requestHeaders,
    redirect: "follow"
  };
  if (data) {
    requestOptions["data"] = data;
  }
  if (body) {
    requestOptions["body"] = body;
  }
  if (process.env.NODE_ENV === "development") {
    console.log("pending", {
      route,
      requestOptions
    });
  }
  return await fetch(route, requestOptions)
    .then(response => {
      return response.json();
    })
    .catch(err => {
      throw err;
    });
}

export { configs, request };
