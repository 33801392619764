import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Switch, Redirect, Route, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import { GlobalStyle } from "./styles/components";
import { Auth0Provider } from "./util/auth";
import { ToastProvider } from 'react-toast-notifications'
import asyncComponent from "./util/AsyncComponent";

// Layout Imports
import ApplicationLayout from "./components/Layout/ApplicationLayout";
import ScrollToTop from "./components/Layout/ScrollToTop";
import Authentication from "./components/Layout/Authentication";

// https://facebook.github.io/create-react-app/docs/adding-bootstrap
import "bootstrap/dist/css/bootstrap.css";

// Code Split our Routes
const EmployeeDetail = asyncComponent(() => import("./pages/EmployeeDetail"));
const EmployeeEDL = asyncComponent(() => import("./pages/EmployeeEDL"));
const Home = asyncComponent(() => import("./pages/Home"));
const EDL = asyncComponent(() => import("./pages/EDL"));
const Document = asyncComponent(() => import("./pages/Documents"));
const AttendanceLOA = asyncComponent(() => import("./pages/AttendanceLOA"));

const RedirectToDefaultPage = () => <Redirect to="/employees/" />;

const App = () => (
  <Fragment>
    <Helmet>
      <title>HR Generalist</title>
    </Helmet>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastProvider>
        <Auth0Provider>
          <Authentication>
            <BrowserRouter>
              <ScrollToTop>
                <ApplicationLayout>
                  <Switch>
                    <Route exact path="/" component={Home}>
                      <RedirectToDefaultPage />
                    </Route>
                    {/* Home */}
                    <Route exact path="/employees/" component={Home} />
                    {/* Employee Detail */}
                    <Route
                      exact
                      path="/employees/:employeeID/"
                      component={EmployeeDetail}
                    />
                    <Route
                      exact
                      path="/employees/:employeeID/documents"
                      component={EmployeeEDL}
                    />
                    {/* Attendance */}
                    <Route exact path="/attendance/" component={AttendanceLOA} />
                    {/* EDL (electronic document library) */}
                    <Route exact path="/edl/" component={EDL} />
                    {/* Documents */}
                    <Route exact path="/documents/" component={Document} />
                    {/* when no other matches are found just redirect to /employees/ */}
                    <Route>
                      <RedirectToDefaultPage />
                    </Route>
                  </Switch>
                </ApplicationLayout>
              </ScrollToTop>
            </BrowserRouter>
          </Authentication>
        </Auth0Provider>
      </ToastProvider>
    </ThemeProvider>
  </Fragment>
);

ReactDOM.render(<App />, document.getElementById("root"));
